import React from 'react';

const SizeChartPage = () => {
    return (
        <div>
            SizeChart Page here...
        </div>
    );
};

export default SizeChartPage;